@use 'sass:color';
@import "bulma/sass/base/minireset";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

@font-face {
    font-family: 'Euclidflex webs';
    src: url('EuclidFlex-Light-WebS.woff') format('woff'),
}

$background-image: url("eisberg_1920x2560.png");

$branding-font: 'Euclidflex webs', sans-serif;
$font: Verdana, sans-serif;

// primary color palette
$dark-blue: #02637D;
$blue: #37C3D5;
$light-blue: #78D0D8;
$light-gray: #999999;
$gray: #5F5F5F;

// secondary color palette
$red: #C24646;
$light-red: #DE5855;
$orange: #FF8664;
$light-orange: #FFCE71;
$yellow: #FFF078;

// tertiary color palette
$dark-green: #005F64;
$green: #03B57F;
$light-green: #41DBC4;
$deep-blue: #02ADC5;
$light-violet: #93AFE2;


$inner-gap-width: 1px;
$gap-width: 15px;

body {
    padding: 15px;
    background-image: $background-image;
}

body, input, select, option, button, i.tooltip > div { font-family: $font; }
body.branding {
    &, input, select, option, button, i.tooltip > div { font-family: $branding-font; }
}

h1, h2 {
    font-family: $branding-font;
    font-weight: normal;
    color: white;
}

h1 { font-size: 36px; }
h2 {
    font-size: 24px;
    margin-bottom: 5px;
    span { font-size: 16px; }
}

input {
    padding-right: 2px;
    border: 1px solid gray;
}

.fa-info-circle {
    color: rgb(30%, 30%, 30%);
}

i.tooltip {
    margin-left: 5px;
    position: relative;

    >div {
        visibility: hidden;
        width: 450px;
        background-color: rgba(20%, 20%, 20%, 0.9);
        color: white;
        text-align: left;
        line-height: 17px;
        font-weight: normal;
        padding: 10px;
        box-shadow: 0 0 11px rgba(20%, 20%, 20%, 0.5);

        position: absolute;
        z-index: 1;
        top: -5px;
        left: 105%;
        margin-left: 15px;

        em {
            font-style: normal;
            font-weight: bold;
        }

        div { margin-bottom: 5px; }
        div.gap { height: 10px; }
    }
    &:hover>div {
        visibility: visible;
      }
}

select, input, textarea, button {
    font-weight: normal;
    font-size: 13px;
}


button {
    background-color: $dark-blue;
    border: 2px solid $blue;
    color: white;
}

div.panel {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 20px;

    h2 { line-height: 1.0; }

    div.content {
        font-weight: normal;
        font-size: 13px;
    }
}

div.grid-container {
    display: grid;
    width: 100%;
    grid-template-areas:
        "head head"
        "target chart"
        "plan plan";
    grid-template-rows: 65px 560px 1fr;
    grid-template-columns: 400px 1fr;
    gap: $gap-width;
}

div.head {
    grid-area: head;
    display: grid;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    grid-template-columns: 400px 1fr 213px;
    gap: $gap-width;

    div.storage {
        margin-top: 5px;

        label {
            display: inline-block;
            width: 100px;
            color: $dark-blue;
        }
        button, input, select {
            margin-right: 5px;
            margin-bottom: 5px;
        }

        input, select { width: 300px; }
    }

    img#Logo { width: 200px; }
}

div.panel.target  {
    grid-area: target;

    div.globals, div.short-term, div.long-term {
        padding: 6px;
        width: 100%;
        margin-bottom: $inner-gap-width;
        background-color: color.scale($light-gray, $lightness: 80%);

        input {
            width: 60px;
            text-align: right;
            font-size: 13px;
        }

        input.percent { width: 38px; }

        div.labeled {
            margin-bottom: 5px;
            text-align: right;
            label {
                color: $dark-blue;
                float: left;
                clear: left;
            }
            div.tooltip {
                float: right;
                padding-top: 2px;
                width: 18px;
            }
        }
        div.labeled.depth1 { padding-left: 10px; }
    }
}

div.panel.chart  {
    grid-area: chart;
    width: 100%;

    div.content {
        height: 100%;
        width: 100%;
        background-color: color.scale($light-gray, $lightness: 80%);
        border-radius: 10px;
        padding: 10px;

        button {
            position: absolute;
            margin-top: 10px;
            right: 70px;
            z-index: 1;
        }

        img.chart-logo {
            position: absolute;
            margin-top: 45px;
            width: 100px;
            right: 70px;
            z-index: 1;
        }

        div.chart-tooltip {
            background-color: white;
            border: 1px solid gray;
            padding: 5px;

            label {
                display: block;
                margin-bottom: 5px;
            }

            div {
                &.line-item { margin-bottom: 5px; }
                span {
                    float: right;
                    margin-left: 20px;
                }
            }
        }
    }
}

div.panel.plan  {
    grid-area: plan;

    .fa-info-circle { color: white; }

    div.table-container { position: relative; }

    table {
        table-layout: fixed;
        width: 100%;

        tr.columns td { visibility: hidden; }

        td {
            background-color: color.scale($light-gray, $lightness: 90%);
            padding: 0px 1px;
        }

        tr.source td input {
            width: 100%;
            height: 100%;
            text-align: center;
        }

        tr.main-headers, tr.scope-headers {
             td {
                border: 1px solid rgba(0,0,0, 0.5);
                color: white;
                padding: 5px;
            }
        }

        tr.column-headers td {
            color: $dark-blue;
            .fa-info-circle {color: $dark-blue; }
        }

        tr.main-headers td {
            background-color: $dark-blue;;
            font-size: 18px;

            div.show-all-scopes {
                float: right;
                margin-top: 4px;
                font-size: 13px;
                input { margin-left: 5px; }
            }
        }

        tr.scope-headers td {
            background-color: $blue;
            color: white;
            font-size: 15px;

            div.list-buttons {
                float: right;
                button { margin-right: 5px; }
                div.tooltip { display: inline-block; }
            }
        }

        td.overridden, td.default {
            position: relative;
        }

        td.default input {
            color: rgb(30%, 30%, 30%);
            background-color: color.scale($light-gray, $lightness: 80%);
        }

        td.overridden input {
            color: black;
            background: white;
        }

        tr.source.has-notes {
            td.overridden div {
                position: absolute;
                right: 4px;
                top: 4px;
                width: 5px;
                height: 5px;
                background-color: gray;
            }
        }

        td.feasibility { border-left: 1px solid rgba(0,0,0, 0.5); }

        tr.gap td {
            background-color: rgba(0%, 0%, 0%, 0);
            height: 10px;
        }

        td.no {width: 25px; }
        td.name { width: 355px; }
        td.footprint { width: 50px; }
        td.feasibility { width: 42px; }
        td.delete { width: 30px; }

        tr.source {
            td.name input {
                text-align: left;
                text-overflow: ellipsis;
            }

            td.no { text-align: right; }
            td.footprint input { text-align: right; }
            td.feasibility { text-align: center; }
        }

        td.gap-col {
            width: 10px;
            background-color: transparent !important;
        }

        td.year {
            text-align: center;
            vertical-align: middle;
            span {
                display:block;
                transform: rotate(-90deg);
                &.overridden { color: black; }
            }
            height: 40px;
        }

        td.default-reduction {
            div.label { margin-left: 4px; }
            span {
                float: right;
                input {
                    width: 30px;
                    margin-left: 10px;
                    text-align: right;
                }
                div.tooltip { display: inline-block; }
            }
        }
    }

    div.override-notes {
        position: absolute;
        width: 200px;
        height: 100px;
        background-color: rgb(70%, 70%, 70%);
        border: 1px solid black;
        padding: 2px 4px;
        div { margin-bottom: 3px; }
        textarea {
            width: 100%;
            height: 70px;
        }
        box-shadow: 3px 3px 1px rgba(black, 0.6);;
    }

    div.upload {
        width: 500px;
        margin-top: 15px;
        padding: 4px;
        background-color: color.scale($light-gray, $lightness: 80%);
        color: $dark-blue;

        .fa-info-circle { color: $dark-blue; }

        div.tooltip {
            float: right;
            clear: right;
            padding-top: 2px;
            margin-right: 2px;
            width: 18px;
        }

        div.controls {
            margin-top: 4px;
        }

        label {
            display: inline-block;
            background-color: $dark-blue;
            padding: 2px 4px;
            border: 2px solid $blue;
            color: white;
            margin-right: 10px;
            input[type='file'] { display: none; }
        }

        a { color: $dark-blue; }
    }
}

div.server-status-alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border: 2px solid red;
    box-shadow: 10px 5px 5px rgba(20%, 20%, 20%, 0.8);
    padding: 10px;
}

div.status-area {
    position: fixed;
    width: 400px;
    top: 50%;
    //bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $dark-blue;
    color: white;
    box-shadow: 10px 5px 5px rgba(20%, 20%, 20%, 0.8);

    &>div {
        padding: 10px;
        padding-bottom: 50px;

        button {
            position: absolute;
            right: 12px;
            bottom: 12px;
        }
    }
}